import * as React from "react";
import {Helmet} from "react-helmet";
import logo from '../images/lewdnet.jpg';
import vr from '../images/vr-alt.png';
import '../style/index.css';

// markup
const IndexPage = () => {
  return (
    <main>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Lewdnet.com</title>
      <meta name="description" content="Lewdnet.com | Story based adult VR game"/>
    </Helmet>
      <div id="bg">
        <img id="logo" src={logo} alt=""/>
        <h1>2022 Q1</h1>
      </div>
      <img id="vr" src={vr} alt=""/>
    </main>
  )
}

export default IndexPage
